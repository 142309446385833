<template>
  <surround-box
    specialColor="#0a3444"
    background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)"
    title="生猪年养殖规模"
  >
    <div class="chart-box">
      <div ref="chart" class="chart"></div>
      <div class="unit">单位：{{ unit }}</div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
import * as echarts from "echarts";

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      data: [],
      unit: "",
    };
  },
  methods: {
    //   初始化
    initChart() {
      let chart = echarts.init(this.$refs.chart);
      let options = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          containLabel: true,
          top: 48,
          left: 16,
          right: 32,
          bottom: 16,
        },
        xAxis: {
          data: this.data.map((item) => item.dimensionName),
          axisLabel: {
            color: "#fff",
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: ["#fff"],
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgba(255,255,255,0.5)"],
              type: "dashed",
            },
          },
        },
        yAxis: {
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgba(255,255,255,0.5)"],
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "line",
            data: this.data.map((item) => item.content),
            smo0th: true,
            lineStyle: {
              color: "#36A8FF",
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(54, 168, 255, 0.8)",
                },
                {
                  offset: 0.8,
                  color: "rgba(54, 168, 255, 0)",
                },
              ]),
            },
          },
        ],
      };
      chart.setOption(options);
    },
    getStatics() {
      return this.$post(this.$api.STATICS_MANAGE.GET2, {
        resourceTypeId: 18,
      }).then((res) => {
        this.data = res;
        this.unit = res[0].contentUnit;
      });
    },
  },
  mounted() {
    this.getStatics().then(() => {
      this.initChart();
    });
  },
};
</script>
<style lang='less' scoped>
.chart-box {
  width: 100%;
  height: 230px;
  position: relative;
  .chart {
    width: 100%;
    height: 200px;
  }
  .unit {
    position: absolute;
    left: 16px;
    top: 12px;
    color: #fff;
    font-weight: bold;
  }
}
</style>